import { RouteRecordRaw } from 'vue-router'

export const Home: RouteRecordRaw = {
  name: 'Home',
  path: '/home:id',
  component: () => import('@/views/Editor/index.vue'),
}


export const View: RouteRecordRaw = {
  name: 'View',
  path: '/view/:id',
  component: () => import('@/views/Mobile/view.vue')
}

const constantRoutes = [
  Home,
  View,
]

export default constantRoutes