import { defineStore } from 'pinia'
import tinycolor from 'tinycolor2'
import { omit } from 'lodash'
import api from '@/api'
import { Slide, SlideTheme, PPTElement, PPTAnimation } from '@/types/slides'
import { slides } from '@/mocks/slides'
import { theme } from '@/mocks/theme'
import { layouts } from '@/mocks/layout'
import { message } from 'ant-design-vue'



interface RemoveElementPropData {
  id: string
  propName: string | string[]
}

interface UpdateElementData {
  id: string | string[]
  props: Partial<PPTElement>
}

interface FormatedAnimation {
  animations: PPTAnimation[]
  autoNext: boolean
}

export interface SlidesState {
  id: number
  theme: SlideTheme
  slides: Slide[]
  slideIndex: number
  viewportRatio: number
  width?: number
  height?: number
  thumb?: []
}




export const useSlidesStore = defineStore('slides', {
  state: (): SlidesState => ({
    id: 0,
    theme: theme, // 主题样式
    slides: slides, // 幻灯片页面数据
    slideIndex: 0, // 当前页面索引
    viewportRatio: 0.5625, // 可是区域比例，默认16:9
    width: 0, // 宽度
    height: 0, // 高度
    thumb: []
  }),

  getters: {
    currentSlide(state) {
      return state.slides[state.slideIndex]
    },
    getThumb(state) { 
      return state.thumb
    },
    currentSlideAnimations(state) {
      const currentSlide = state.slides[state.slideIndex]
      if (!currentSlide?.animations) return []

      const els = currentSlide.elements
      const elIds = els.map(el => el.id)
      return currentSlide.animations.filter(animation => elIds.includes(animation.elId))
    },

    // 格式化的当前页动画
    // 将触发条件为“与上一动画同时”的项目向上合并到序列中的同一位置
    // 为触发条件为“上一动画之后”项目的上一项添加自动向下执行标记
    formatedAnimations(state) {
      const currentSlide = state.slides[state.slideIndex]
      if (!currentSlide?.animations) return []

      const els = currentSlide.elements
      const elIds = els.map(el => el.id)
      const animations = currentSlide.animations.filter(animation => elIds.includes(animation.elId))

      const formatedAnimations: FormatedAnimation[] = []
      for (const animation of animations) {
        if (animation.trigger === 'click' || !formatedAnimations.length) {
          formatedAnimations.push({ animations: [animation], autoNext: false })
        }
        else if (animation.trigger === 'meantime') {
          const last = formatedAnimations[formatedAnimations.length - 1]
          last.animations = last.animations.filter(item => item.elId !== animation.elId)
          last.animations.push(animation)
          formatedAnimations[formatedAnimations.length - 1] = last
        }
        else if (animation.trigger === 'auto') {
          const last = formatedAnimations[formatedAnimations.length - 1]
          last.autoNext = true
          formatedAnimations[formatedAnimations.length - 1] = last
          formatedAnimations.push({ animations: [animation], autoNext: false })
        }
      }
      return formatedAnimations
    },
  
    layouts(state) {
      const {
        themeColor,
        fontColor,
        fontName,
        backgroundColor,
      } = state.theme

      const subColor = tinycolor(fontColor).isDark() ? 'rgba(230, 230, 230, 0.5)' : 'rgba(180, 180, 180, 0.5)'

      const layoutsString = JSON.stringify(layouts)
        .replaceAll('{{themeColor}}', themeColor)
        .replaceAll('{{fontColor}}', fontColor)
        .replaceAll('{{fontName}}', fontName)
        .replaceAll('{{backgroundColor}}', backgroundColor)
        .replaceAll('{{subColor}}', subColor)
      return JSON.parse(layoutsString)
    },
  },

  actions: {
    setTheme(themeProps: Partial<SlideTheme>) {
      this.theme = { ...this.theme, ...themeProps }
    },
    setViewportRatio(viewportRatio: number) {
      this.viewportRatio = viewportRatio
    },
    setWidth(width: number) {
      this.width = width
    },
    setHeight(height: number) {
      this.height = height
    },
    // 设置布局宽高
    setViewportWidthHeight() {
      const rate = Number(this.height) / Number(this.width)
      this.viewportRatio = Number(rate.toFixed(4))
    },

    // 获取所有字体分类
    async getFontCate() { 
      return await api.get('v2/font-cate?per-page=50')
    },
    // 获取所有字体
    async getAllFont() { 
      return await api.get('v2/font/all')
    },

    // 一级字体
    async getIndexFont(id: number) {
      return await api.get('v2/font/' + id + '?t=video' )
    },

    // 动态加载
    async getFontUrl(name: string) {
      return await api.get('v2/font/search?keyword=' + name)
    },

    // 获取数据
    getData(id: number) {
      api.get('v2/video/' + id, {}).then(response => {
        const data = response.data.data
        this.thumb = response.data.thumb
        if (data) {
          const slideData: Slide[] = JSON.parse(data)
          this.setSlides(slideData)
          slideData.map(item => {
            if (item.viewportRatio) {
              this.setViewportRatio(item.viewportRatio)
            }
            if (item.width) {
              this.width = item.width
            }
            if (item.height) {
              this.height = item.height
            }
            if (item.width && item.height) {
              const rate = Number(item.height) / item.width
              this.setViewportRatio(Number(rate.toFixed(4)))
            }
          })
        }
      })
    },

    resetData() {
      const slideData = this.slides
      slideData.map(item => {
        if (item.viewportRatio) {
          this.setViewportRatio(item.viewportRatio)
        }
        if (item.width) {
          this.setWidth(item.width)
        }
        if (item.height) {
          this.setHeight(item.height)
        }
        if (item.width && item.height) {
          const rate = Number(item.height) / item.width
          this.setViewportRatio(Number(rate.toFixed(4)))
        }
      })
      this.setSlides(slideData)
    },
    // 同步数据
    syncData() { 
      const slideData = JSON.stringify(this.slides)
      if (slideData) {
        api.put(`v2/video/${this.id}`, {
          data: slideData
        }).then(response => {
          const code: number = response['code']
          if ( code === 200 ) {
            message.success('数据同步完成')
          }
        })
      }
    },
    setSlides(slides: Slide[]) {
      this.slides = slides
    },

    // 获取数量
    total() { 
      return this.slides.length
    },
  
    addSlide(slide: Slide | Slide[]) {
      const slides = Array.isArray(slide) ? slide : [slide]
      const addIndex = this.slideIndex + 1
      this.slides.splice(addIndex, 0, ...slides)
      this.slideIndex = addIndex
    },
  
    updateSlide(props: Partial<Slide>) {
      const slideIndex = this.slideIndex
      this.slides[slideIndex] = { ...this.slides[slideIndex], ...props }
      this.syncData()
    },
  
    deleteSlide(slideId: string | string[]) {
      const slidesId = Array.isArray(slideId) ? slideId : [slideId]
  
      const deleteSlidesIndex = []
      for (let i = 0; i < slidesId.length; i++) {
        const index = this.slides.findIndex(item => item.id === slidesId[i])
        deleteSlidesIndex.push(index)
      }
      let newIndex = Math.min(...deleteSlidesIndex)
  
      const maxIndex = this.slides.length - slidesId.length - 1
      if (newIndex > maxIndex) newIndex = maxIndex
  
      this.slideIndex = newIndex
      this.slides = this.slides.filter(item => !slidesId.includes(item.id))
      this.syncData()
    },
  
    updateSlideIndex(index: number) {
      this.slideIndex = index
      this.syncData()
    },
  
    addElement(element: PPTElement | PPTElement[]) {
      const elements = Array.isArray(element) ? element : [element]
      const currentSlideEls = this.slides[this.slideIndex].elements
      const newEls = [...currentSlideEls, ...elements]
      this.slides[this.slideIndex].elements = newEls
    },

    deleteElement(elementId: string | string[]) {
      const elementIdList = Array.isArray(elementId) ? elementId : [elementId]
      const currentSlideEls = this.slides[this.slideIndex].elements
      const newEls = currentSlideEls.filter(item => !elementIdList.includes(item.id))
      this.slides[this.slideIndex].elements = newEls
      this.syncData()
    },
  
    updateElement(data: UpdateElementData) {
      const { id, props } = data
      const elIdList = typeof id === 'string' ? [id] : id
  
      const slideIndex = this.slideIndex
      const slide = this.slides[slideIndex]
      const elements = slide.elements.map(el => {
        return elIdList.includes(el.id) ? { ...el, ...props } : el
      })
      this.slides[slideIndex].elements = (elements as PPTElement[])
      this.syncData()
    },
  
    removeElementProps(data: RemoveElementPropData) {
      const { id, propName } = data
      const propsNames = typeof propName === 'string' ? [propName] : propName
  
      const slideIndex = this.slideIndex
      const slide = this.slides[slideIndex]
      const elements = slide.elements.map(el => {
        return el.id === id ? omit(el, propsNames) : el
      })
      this.slides[slideIndex].elements = (elements as PPTElement[])
      this.syncData()
    },
  },
})