import axios from 'axios'
import { message } from 'ant-design-vue'


const api = axios.create(
  {
    baseURL: 'https://api.print.num.show/',
    timeout: 10000,
    responseType: 'json'
  }
)
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.common['Accept'] = 'application/json'

api.interceptors.response.use(
  response => {
    if (response.data.code === 200) {
      return Promise.resolve(response.data)
    }

    if ( response.data.code === 401 ) {
      message.error(response.data.message)
    }
    else {
      // 这里做错误提示
      message.error(response.data.message)
      return Promise.reject(response.data)
    }
  },
  error => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = '接口' + message.substr(message.length - 3) + '异常'
    }
    message({
      message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default api
