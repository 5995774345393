import { Slide } from '@/types/slides'

export const slides: Slide[] = [
  {
    id: 'test-slide-1',
    elements: [
      {
        type: 'text',
        id: 'idn7Mx',
        left: 355,
        top: 65.25,
        width: 585,
        height: 188,
        lineHeight: 1.2,
        content: '<p><strong><span style=\'font-size:  112px\'>爱扮兔</span></strong></p>',
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333'
      }
    ],
    background: {
      type: 'solid',
      color: '#ffffff',
    },
  },
]